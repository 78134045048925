import { useRouter } from "next/router";
import qs from "qs";

import { config } from "shared/data";

import { QueryParameter as TopicAndAuthorQueryParameter } from "scmp-app/components/letter-list/consts";
import { sanitizedParametersByKeys } from "scmp-app/lib/router/hooks";
import { getAbsoluteUrl, isValidUrl } from "scmp-app/lib/utils";

export const SanitizedUrlKeys = [TopicAndAuthorQueryParameter];

export const useCanonicalUrl = (url?: string) => {
  const router = useRouter();
  const canonicalUrl = getAbsoluteUrl(url ?? router.asPath, config.general.canonicalBaseUrl);
  if (isValidUrl(canonicalUrl)) {
    const url = new URL(canonicalUrl);
    const { sanitizedQueryString } = sanitizedParametersByKeys(SanitizedUrlKeys, url.search);
    url.search = qs.stringify({ ...sanitizedQueryString });
    return {
      canonicalUrl: url.toString(),
    };
  }

  return {
    canonicalUrl,
  };
};
