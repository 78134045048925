import type { MetaTag } from "next-seo";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type { hooksUseDeepLinkTagsBase$key } from "scmp-app/queries/__generated__/hooksUseDeepLinkTagsBase.graphql";
import type { hooksUseDynamicMetaTags$key } from "scmp-app/queries/__generated__/hooksUseDynamicMetaTags.graphql";

import type { ControlledTagKey } from "./enums";
import { isControlledTagKey } from "./enums";

const defaultKeyword =
  "South China Morning Post, News, Opinion, China, Hong Kong, World, US, Asia, Business, Economy, Technology, Lifestyle, Sport";

export const useKeywordMetaTags = (keyword = defaultKeyword, shouldKeepDefault = false) => {
  const keywordSeparator = ", ";

  const getUniqArray = (array: string[]) => [...new Set(array)];

  const content = shouldKeepDefault
    ? getUniqArray([
        ...defaultKeyword.split(keywordSeparator),
        ...keyword.split(keywordSeparator),
      ]).join(keywordSeparator)
    : keyword;

  return [
    {
      content,
      name: "keywords",
    },
    {
      content,
      name: "news_keywords",
    },
  ] as MetaTag[];
};

export const useDeepLinkTags = (entity_: hooksUseDeepLinkTagsBase$key) => {
  const entity = useFragment(
    graphql`
      fragment hooksUseDeepLinkTagsBase on BaseWithApplicationAndUrlAlias {
        __typename
        urlAlias
        entityId
      }
    `,
    entity_,
  );

  const deepLinkPath = useMemo(() => {
    switch (entity.__typename) {
      case "%other":
        return `scmp:/${entity.urlAlias}`;
      case "Article":
        return `scmp:/${entity.urlAlias}?entity=node&type=article&id=${entity.entityId}`;
      case "Section":
        return `scmp:/${entity.urlAlias}?entity=term&type=section&id=${entity.entityId}`;
      case "Topic":
        return `scmp:/${entity.urlAlias}?entity=term&type=topic&id=${entity.entityId}`;
    }
  }, [entity]);

  return [
    {
      content: deepLinkPath,
      property: "al:ios:url",
    },
    {
      content: deepLinkPath,
      property: "al:android:url",
    },
  ] as MetaTag[];
};

type ControlledMetaTags = Partial<Record<ControlledTagKey, string>>;
type UncontrolledMetaTagKeys = Exclude<string, ControlledTagKey>;
type UncontrolledMetaTags = Record<UncontrolledMetaTagKeys, string>;

export const useDynamicMetaTags = (entity_: hooksUseDynamicMetaTags$key) => {
  const entity = useFragment(
    graphql`
      fragment hooksUseDynamicMetaTags on DynamicMetaTags {
        metatags {
          key
          value
        }
      }
    `,
    entity_,
  );
  return (entity.metatags ?? []).reduce<{
    controlled: ControlledMetaTags;
    uncontrolled: UncontrolledMetaTags;
  }>(
    (metaTags, metaTag) => {
      if (metaTag && metaTag.key && metaTag.value) {
        if (isControlledTagKey(metaTag.key)) {
          metaTags.controlled[metaTag.key] = metaTag.value;
        } else {
          metaTags.uncontrolled[metaTag.key] = metaTag.value;
        }
      }
      return metaTags;
    },
    { controlled: {}, uncontrolled: {} },
  );
};
